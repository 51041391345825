.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html {
  --scrollbarBG: #2c3d6122;
  --thumbBG: #2c3d6166;

  height: 100%;
  background: #f3f4f7;
}
body *,
html {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: transparent;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  /*width: 100%;*/
  margin: 0;
  padding: 0;
  border-radius: 15px;
}
::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

input.Input__input___3e_bf:focus {
  outline: none;
}
.toast {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 16px;
  background-color: var(--lightSquidInk);
  font-size: 18px;
  color: #fff;
  text-align: "center";
  display: "block";
}
.Toast__toast___XXr3v {
  text-align: center;
  display: block;
  font-size: 18px;
}
/*******/

@font-face {
  font-family: "MuseoSans300";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/MuseoSans_300.ttf");
}
@font-face {
  font-family: "MuseoSans500";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/MuseoSans_500.otf");
}
@font-face {
  font-family: "ProximaBold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf");
}
@font-face {
  font-family: "AgencyBold";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/AgencyFBBold.ttf");
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.bgStyle {
  background: #181c28 !important;
  padding: 100px;
}
@media only screen and (max-width: 855px) {
  .bgStyle {
    padding: 80px 10px;
  }
}
